import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, IonContent, IonInfiniteScroll, ModalController, Platform } from '@ionic/angular';
import { DataService } from 'src/app/_services/data.service';
import { ChartModalPage } from '../chart-modal/chart-modal.page';
import * as moment from "moment";
import { CryptoService } from 'src/app/_services/crypto.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

@Component({
  selector: 'app-poultry',
  templateUrl: './poultry.page.html',
  styleUrls: ['./poultry.page.scss'],
})
export class PoultryPage implements OnInit {
  subCommodityName: any;
  subCommodityId: any;
  storeRateData: any;
  boardRateData: any;
  mainCommodityId: any;
  loader = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  isLoading = true;
  isMessages = false;
  count = 0;
  messages: any = [];
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent, { static: false }) content: IonContent;
  isSubscribed = true;
  isCordova: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    public modalController: ModalController,
    private alertController: AlertController,
    public platform: Platform,
    private socialSharing: SocialSharing,
    private cryptoService: CryptoService,
    private ga: GoogleAnalytics,
  ) {

    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.isCordova = true;
        this.platform.resume.subscribe((obs: any) => {
          this.boardRates(this.subCommodityId);
        });
        this.platform.pause.subscribe((obs: any) => {
        });
      } else {
        this.isCordova = false;
      }
    });
  }
  async segmentChanged(event) {
    //console.log(event.target.value);
    if (event.target.value === 'rates') {
      this.isMessages = false;
      this.isLoading = true;
      this.boardRates(this.subCommodityId);
    } else {
      this.isMessages = true;
      this.isLoading = true;
      this.count = 0;
      this.getMessages('');
    }
  }
  ionViewDidEnter(){
    
    this.ga.trackView('Poultry Rates/Messages')
    .then(() => {})
    .catch(e => console.log(e));
  }
  async segmentChangedNotsubscribed(event) {
    if (event.target.value === 'rates') {
      if (this.isSubscribed) {
        this.isMessages = false;
        this.isLoading = true;
        this.boardRates(this.subCommodityId);
      } else {
        const alert = await this.alertController.create({
          header: 'Alert',
          backdropDismiss: false,
          message: 'You\'re not subscribed for live rates. <br /> Please call our customer care <br />  <a href="tel:+91-9845544475">+91-9845 544 475</a>  <br /> to subscribe.',
          buttons: [{
            text: 'Okay',
            cssClass: 'primary',
            handler: () => {
            }
          }]
        });
        await alert.present();
      }
    } else {
      this.isMessages = true;
      this.isLoading = true;
      this.count = 0;
      this.getMessages('');
    }
  }
  getMessages(event) {

    this.dataService.getAllMessagesOnUserIDSId(this.subCommodityId, this.count).then((resp: any) => {
      this.messages = this.messages.concat(resp);
      this.count = this.count + 10;
      window.dispatchEvent(new Event('resize'));
      this.isLoading = false;
      if (event !== '') {
        event.target.complete();
      }
    });
  }
  loadData(event: any) {
    this.getMessages(event);
  }
  sortFunc(a, b) {
    return b.id - a.id;
  }

  ngOnInit() {
    this.mainCommodityId = this.activatedRoute.snapshot.params.mainid;
    this.subCommodityId = this.activatedRoute.snapshot.params.subid;
    this.subCommodityName = this.activatedRoute.snapshot.params.name;
    const status = localStorage.getItem('userType');
    //  if (this.mainCommodityId) {

    this.dataService.getSubscribedSubcommodiyStatus(this.subCommodityId).then((resp: any) => {
      if (resp === 'True') {
        this.isSubscribed = true;
        this.boardRates(this.subCommodityId);
      } else {
        this.isSubscribed = false;
        this.isMessages = true;
        this.getMessages('');
      }
    });

    //  }  
    // this.platform.resume.subscribe(async () => {
    // this.boardRates(this.subCommodityId);
    // });
  }
  storeRates(id: any) {
    this.dataService.getStoreRate(id).then((resp) => {
      this.storeRateData = resp;
    });
  }
  boardRates(id: any) {
    this.dataService.getBoardRate(id).then((resp) => {
      this.storeRates(this.subCommodityId);
      this.boardRateData = resp;
      this.isLoading = false;
    });
  }

  changeDateColor(data: any) {
    const date = new Date();
    if (new Date(data) >= date) {
      return 'text-success';
    } else {
      return 'text-danger';
    }
  }


  async onRateClick(chartId: any, storeId: any, PName: string, PType: string, PPType: string, QtyMeas: string, type: number) {
    const modal = await this.modalController.create({
      component: ChartModalPage,
      componentProps: {
        chartId,
        graphType: type,
        storeId,
        productInfo: PName + '' + PType + '' + PPType + '' + QtyMeas,
        isStoreDetail: false
      }
    });
    return await modal.present();
  }
  checkTime(date: any) {
    const today = moment();
    const dateToCheck = moment(date).subtract(1, 'd');
    const isAtLeastADayAgo = today.diff(dateToCheck, 'd') > 1;
    if (isAtLeastADayAgo) {
      return 'text-danger';
    } else {
      return 'text-success';
    }
  }
  whatsappShare(city: any) {
    console.log(city);
    let shareContent: string;
    shareContent = this.subCommodityName + ' rates in ' + city.cityName + ':\n\n';
    city.rates.forEach((e: any, i: number, arr: any) => {
      let udate = moment(e.pricedata.date).format('DD-MM-YYYY  hh:mm a');
      shareContent += e.Type + 'ing ' + e.cur + e.pricedata.price + ' (' + e.change + ').\n\n';
    });
    shareContent += 'Shared from OnlineCMR app. \nVisit https://www.onlinecmr.in to get more information.';
    console.log(shareContent)
    if (this.isCordova) {
      this.socialSharing.shareViaWhatsApp(shareContent, null, null);
    } else {
      window.open('https://api.whatsapp.com/send?text=' + shareContent + '', '_system');
    }
  }
  messageShare(message: any) {
    let shareContent = '';
    if(message.Title) {
      shareContent += '*' + message.Title + '*\n'
    }
     shareContent += message.msg;
    shareContent += '\n\n\nShared from OnlineCMR app. \nVisit https://www.onlinecmr.in to get more information.';
    if (this.isCordova) {
      this.socialSharing.shareViaWhatsApp(shareContent, null, null);
    } else {
      window.open('https://api.whatsapp.com/send?text=' + shareContent + '', '_system');
    }
  }
}
