import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {


  constructor() { }
  
  encryptUsingAES256(request): Observable<any> {
    let _key = CryptoJS.enc.Utf8.parse(environment.randomString);
    let _iv = CryptoJS.enc.Utf8.parse(environment.randomString);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(request), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    return of(encrypted.toString());
  }
  decryptUsingAES256(request): Observable<any> {
    if (request) {
      let _key = CryptoJS.enc.Utf8.parse(environment.randomString);
      let _iv = CryptoJS.enc.Utf8.parse(environment.randomString);
      const strId = CryptoJS.AES.decrypt(
        request, _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
        console.log('strid', strId.replace(/"/g,""));
      return of(strId.replace(/"/g,""));
    } 
    else {
      return of(undefined);
    }
    
  }
}
