import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { MenuController, AlertController, NavController, Platform } from '@ionic/angular';
import { DataService } from 'src/app/_services/data.service';
import { CryptoService } from 'src/app/_services/crypto.service';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit {
  mainCommodities: any;
  subscribeStatus = false;
  firstName: any;
  sliderConfig = {
    slidesPerView: 'auto'
  }
  selectedTab = '';
  subCommodities: any;
  marketList: any;
  constructor(
    public menu: MenuController,
    private dataService: DataService,
    private alertController: AlertController,
    private navCtrl: NavController,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public platform: Platform,
    private cryptoService: CryptoService,
    private ga: GoogleAnalytics
  ) {
    this.menu.enable(true);
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.platform.resume.subscribe((obs: any) => {
          this.getSubscribedCommodities();
          this.getDashboardMarket();
        });

      }
    });
  }

  ngOnInit() {
    if (!localStorage.getItem('mainCommodities')) {
      this.getMainCommodities();
    } else {
      this.mainCommodities = JSON.parse(localStorage.getItem('mainCommodities'));
    }
    this.getSubscribedCommodities();
    this.getDashboardMarket();
  }
  ionViewDidEnter() {
    this.getSubscribedCommodities();
    this.getDashboardMarket();
     
    this.ga.trackView('Home Page')
      .then(() => { })
      .catch(e => console.log(e));
  }
  getDashboardMarket() {
    this.dataService.getdashboardMarket('0').then((resp: any) => {
      this.marketList = resp;
    });
  }
  getMainCommodities() {
    this.dataService.getMainCommodities().then((resp) => {
      localStorage.setItem('mainCommodities', JSON.stringify(resp));
      this.mainCommodities = resp;
    });
  }
  getSubscribedCommodities() {
    this.dataService.getSubscribedSubcommoties().then(resp => {
      this.subCommodities = resp;
    })
  }
  onListClick(item: any) {
    this.navCtrl.navigateForward('/tabs/home/detail/' + item.id);
  }
  changeTabSubComms(item) {
    const id = +item.sId;
    if (id === 136 || id === 138 || id === 141 || id === 142) {
      this.navCtrl.navigateForward('/tabs/home/' + item.mId + '/poultry/'  + item.sId + '/' + item.SName);
    } else {
      this.navCtrl.navigateForward('/tabs/home/' + item.mId + '/other/' + item.sId + '/' + item.SName);
    }
  }
  segmentChanged(event: any) {

  }
}
