import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { MenuController, NavController, Platform, ToastController } from '@ionic/angular';
import { DataService } from 'src/app/_services/data.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit, OnDestroy {
  loginForm: FormGroup;
  isCordova: boolean;
  constructor(
    private dataService: DataService,
    public menu: MenuController,
    private navController: NavController,
    private formBuilder: FormBuilder,
    private toastCtrl: ToastController,
    private platform: Platform,
    private ga: GoogleAnalytics
    //  public storage: Storage
  ) {
    this.menu.enable(false);
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      mobileNumber: ['', [Validators.required, Validators.pattern('[5-9]\\d{9}')]]
    });
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
     //   console.log('is cordova true');
        this.isCordova = true;
      } else {
     //   this.isCordova = false;
      }
      if (this.isCordova) {
      }
    });
  }
  ngOnDestroy() {
    if (this.isCordova) {
    }
  }

  ionViewDidEnter(){
 //   console.log('login view did load');
    this.ga.trackView('Login Page')
    .then(() => {})
    .catch(e => console.log(e));
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  next(value: any) {
    this.navController.navigateForward('/otp/' + value.mobileNumber);
    this.dataService.getOTP(value.mobileNumber).then(async () => {
    }, error => {
      console.log(error)
    });

  }
 
  async presentToast(header: string, message: string, position: any, duration: number, color: string) {
    const toast = await this.toastCtrl.create({
      header,
      message,
      position,
      duration,
      color
    });
    toast.present();
  }
}
