import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, AlertController, NavController, Platform } from '@ionic/angular';
import { DataService } from 'src/app/_services/data.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { CryptoService } from 'src/app/_services/crypto.service';
import * as moment from "moment";
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
})
export class DetailPage implements OnInit {
  listId: any;
  listName = 'Detail';
  listingData: any;
  relatedProducts: any;
  isEditable = false;
  mainCommodityId: any;
  isCordova: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    public modalController: ModalController,
    public alertController: AlertController,
    private navCtrl: NavController,
    public platform: Platform,
    private socialSharing: SocialSharing,
    private cryptoService: CryptoService,
    private ga: GoogleAnalytics
  ) {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.isCordova = true;
      } else {
        this.isCordova = false;
      }
    });
   }

  ngOnInit() {
    this.listId = this.activatedRoute.snapshot.params.id;
   // this.listName = decodeURIComponent(this.activatedRoute.snapshot.params.name);
    this.getDetail(this.listId);

  }

  getDetail(listId: any) {
    this.ga.trackEvent('User', 'Market Plcae', 'Market Detail' + listId);
    this.dataService.getBuySellDetailOnId(listId).then((resp: any) => {
      this.listingData = resp;
     // console.log(resp);
     const userId = localStorage.getItem('userID'); //  this.cryptoService.decryptUsingAES256(localStorage.getItem('userID'));
      if(+userId === +resp.userId) {
        this.isEditable = true;
      }
      this.mainCommodityId = resp.mId;
      this.getRelated(this.listId, resp.mId, 5)
    });
  }

  getRelated(id, mid, count) {
    this.dataService.getRelatedListings(id, mid, count).then((resp:any) => {
      this.relatedProducts = resp;
    });
  }

  filterFilter(text) {
    if (!text || !text.length) {
      return text;
    }
    return text.replace(/(\\r\\n)|([\r\n])/gmi, '<br/>');
  }

  editList() {
    this.navCtrl.navigateForward('/tabs/market/add-edit/' + this.listId + '/'+ this.mainCommodityId + '/' + 1);

  }

  shareContent() {
    const text = '*' + this.listingData.subject + '*\n\n' + 'Here is one ' + this.listingData.label + 'ing bid by ' + this.listingData.sender + '.\n' +
    'Detail: ' + this.listingData.desc + '\n' +
    'Posted on ' + moment(this.listingData.date).format('DD-MM-YYYY') + '\n\n' +
    'Shared from OnlineCMR app. \nVisit https://www.onlinecmr.in to get more information.';
    return text;
  }
  sendSMS(){
    const text: string = 'Hi ' + this.listingData.sender + ',\n' +
    'I\'m  '+ localStorage.getItem('firstName') + '. interested in ' + this.listingData.label + 'ing bid ' +
    this.listingData.subject + ' posted by you on OnlineCMR. \n ' +
    'Feel free to reach me on this number.';
       this.socialSharing.shareViaSMS(text, this.listingData.mobile);
  }

  ShareWhatsapp(){
    if (this.isCordova) {
    this.socialSharing.shareViaWhatsApp(this.shareContent(), this.listingData.img, null);
    } else {
      window.open('https://api.whatsapp.com/send?text=' + this.shareContent() + '', '_system');
    }

  }

  ShareFacebook(){
    this.socialSharing.shareViaFacebookWithPasteMessageHint(this.shareContent(), this.listingData.img, null /* url */, 'OnlineCMR')
  }

  SendEmail(){
    this.socialSharing.shareViaEmail('text', 'subject', ['email@address.com'])
  }

  SendTwitter(){
    this.socialSharing.shareViaTwitter(this.shareContent(), this.listingData.img, null)
  }

  SendInstagram(){
    this.socialSharing.shareViaInstagram(this.shareContent(), this.listingData.img)
  }
  onListClick(item: any) {
    this.navCtrl.navigateForward('/tabs/market/detail/' + item.id);
  }

}
