import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, IonContent, IonInfiniteScroll, ModalController, Platform } from '@ionic/angular';
import { DataService } from 'src/app/_services/data.service';
import { ChartModalPage } from '../chart-modal/chart-modal.page';
import * as moment from "moment";
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { CryptoService } from 'src/app/_services/crypto.service';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

@Component({
  selector: 'app-other',
  templateUrl: './other.page.html',
  styleUrls: ['./other.page.scss'],
})
export class OtherPage implements OnInit {
  subCommodityName: any;
  subCommodityId: any;
  cityData: any;
  exchangeData: any;
  mainCommodityId: any;
  loader = [1, 2, 3, 4, 5];
  isLoading = true;
  isMessages = false;
  count = 0;
  messages: any = [];
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent, { static: false }) content: IonContent;
  isSubscribed = true;
  isCordova: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private dataServicre: DataService,
    public modalController: ModalController,
    private alertController: AlertController,
    public platform: Platform,
    private socialSharing: SocialSharing,
    private cryptoService: CryptoService,
    private ga: GoogleAnalytics,
  ) {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.isCordova = true;
        this.platform.resume.subscribe((obs: any) => {
          this.getSubSubcommodityData(this.subCommodityId);
        });
        this.platform.pause.subscribe((obs: any) => {
        });
      } else {
        this.isCordova = false;
      }
    });
  }
  ionViewDidEnter(){
    this.ga.trackView('Commodity Rates/Messages')
    .then(() => {})
    .catch(e => console.log(e));
  }
  segmentChanged(event) {
    //console.log(event.target.value);
    if (event.target.value === 'rates') {
      this.isMessages = false;
      this.isLoading = true;
      this.getSubSubcommodityData(this.subCommodityId);
    } else {
      this.isMessages = true;
      this.isLoading = true;
      this.count = 0;
      this.getMessages('');
    }
  }

  async segmentChangedNotsubscribed(event) {
    if (event.target.value === 'rates') {
      if (this.isSubscribed) {
        this.isMessages = false;
        this.isLoading = true;
        this.getSubSubcommodityData(this.subCommodityId);
      } else {
        const alert = await this.alertController.create({
          header: 'Alert',
          backdropDismiss: false,
          message: 'You\'re not subscribed for live rates. <br /> Please call our customer care  <br /> <a href="tel:+91-9845544475">+91-9845 544 475</a> <br />  to subscribe.',
          buttons: [{
            text: 'Okay',
            cssClass: 'primary',
            handler: () => {
            }
          }]
        });
        await alert.present();
      }
    } else {
      this.isMessages = true;
      this.isLoading = true;
      this.count = 0;
      this.getMessages('');
    }
  }
  getMessages(event) {
    this.dataServicre.getAllMessagesOnUserIDSId(this.subCommodityId, this.count).then((resp: any) => {
      this.messages = this.messages.concat(resp);
      this.count = this.count + 10;
      window.dispatchEvent(new Event('resize'));
      this.isLoading = false;
      if (event !== '') {
        event.target.complete();
      }
    });

  }
  renderClass(type) {
    switch (type) {
      case 0:
        return "border-dark";
      case "NA":
        return "border-dark";
      case type > 0:
        return "border-success";
      case type < 0:
        return "border-danger";
    }
  }
  loadData(event: any) {
    this.getMessages(event);
  }
  sortFunc(a, b) {
    return b.id - a.id;
  }
  ngOnInit() {
    this.mainCommodityId = this.activatedRoute.snapshot.params.mainid;
    this.subCommodityId = this.activatedRoute.snapshot.params.subid;
    this.subCommodityName = this.activatedRoute.snapshot.params.name;
    console.log(this.mainCommodityId);
    //if (this.mainCommodityId) {
    this.dataServicre.getSubscribedSubcommodiyStatus(this.subCommodityId).then((resp: any) => {
      if (resp === 'True') {
        this.isSubscribed = true;
        this.getSubSubcommodityData(this.subCommodityId);
      } else {
        this.isSubscribed = false;
        this.isMessages = true;
        this.getMessages('');
      }
    });


    // } 



    // this.platform.resume.subscribe(async () => {
    //   this.getSubSubcommodityData(this.subCommodityId);
    // });
  }
  getSubSubcommodityData(subId: any) {
    this.dataServicre.getSubcommodityData(subId).then((resp: any) => {
      this.cityData = resp;
      this.isLoading = false;
    })
    this.dataServicre.getExchangeDataOnSubCommodityId(subId).then((resp: any) => {
      this.exchangeData = resp;
    });
  }
  async onPriceClick(chartId: any, SId: any, PName: string, PType: string, PPType: string, QtyMeas: string) {
    const modal = await this.modalController.create({
      component: ChartModalPage,
      componentProps: {
        chartId,
        graphType: 1,
        storeId: SId,
        productInfo: PName + ' ' + PType + ' ' + PPType + ' ' + QtyMeas,
        isStoreDetail: false
      }
    });
    return await modal.present();
  }
  checkTime(date: any) {
    const today = moment();
    const dateToCheck = moment(date).subtract(1, 'd');
    const isAtLeastADayAgo = today.diff(dateToCheck, 'd') > 1;
    if (isAtLeastADayAgo) {
      return 'text-danger';
    } else {
      return 'text-success';
    }
  }
  whatsappShare(city: any) {
    let shareContent: string;
    shareContent = this.subCommodityName + ' rates in ' + city.CName + ':\n\n';
    city.Prices.forEach((e: any, i: number, arr: any) => {
      let udate = moment(e.UTime).format('DD-MM-YYYY  hh:mm a');
      shareContent += e.PName + ' ' + e.PPType + ' ' + e.QtyMeas + ' ' +
        e.Symbol + e.MinRate + ' - ' + e.Symbol + e.Maxrate + ' (' + e.Change + ').\n\n';
      if (i === (arr.length - 1)) {
      }
    });
    shareContent += '\n\n\nShared from OnlineCMR app. \nVisit https://www.onlinecmr.in to get more information.';
    if (this.isCordova) {
      this.socialSharing.shareViaWhatsApp(shareContent, null, null);
    } else {
      window.open('https://api.whatsapp.com/send?text=' + shareContent + '', '_system');
    }
  }
  messageShare(message: any) {
    let shareContent = '';
    if(message.Title) {
      shareContent += '*' + message.Title + '*\n'
    }
     shareContent += message.msg;
    shareContent += '\n\n\nShared from OnlineCMR app. \nVisit https://www.onlinecmr.in to get more information.';
    if (this.isCordova) {
      this.socialSharing.shareViaWhatsApp(shareContent, null, null);
    } else {
      window.open('https://api.whatsapp.com/send?text=' + shareContent + '', '_system');
    }
  }
}
