import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { NavController, AlertController, ModalController } from '@ionic/angular';
import { DataService } from 'src/app/_services/data.service';

@Component({
  selector: 'app-subcommodities',
  templateUrl: './subcommodities.page.html',
  styleUrls: ['./subcommodities.page.scss'],
})
export class SubcommoditiesPage implements OnInit {
  loader = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  isLoading = true;
  subCommodities: any;

  isPoultry = false;
  favStores: any;
  userType: any;
  mainCommodityId: any;
  constructor(
    private dataServicre: DataService,
    private navCtrl: NavController,
    private alertController: AlertController,
    public modalController: ModalController,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ga: GoogleAnalytics,
  ) { }

  ngOnInit() {
    const status = localStorage.getItem('userType');
    if (typeof status !==  'undefined') {
      this.userType = JSON.parse(status);
    }
    this.mainCommodityId = this.activatedRoute.snapshot.params.mainid;
    // console.log(this.activatedRoute.pathFromRoot)
    if (this.mainCommodityId) {
      this.getSubCommodities(this.mainCommodityId);
      if (+this.mainCommodityId === 26) {
        this.isPoultry = true;
      } else {
        this.isPoultry = false;
      }
    }
  }

  getSubCommodities(mainId: any) {
    this.dataServicre.getSubCommodities(mainId).then((resp) => {
      this.subCommodities = resp;
      this.isLoading = false;
    });
  }

  async onSubCommodityClick(item: any) {

    const status = localStorage.getItem('userType');
  //  if (typeof status !== 'undefined' && JSON.parse(status)) {
      if (this.isPoultry) {
        this.ga.trackEvent('User', 'Commodity', 'Sub Commodity' + item.sName);
          this.navCtrl.navigateForward('/tabs/main-commodities/'+ this.mainCommodityId + '/poultry/' + item.sId + '/' + item.sName);
      } else {
        this.ga.trackEvent('User', 'Commodity', 'Sub Commodity' + item.sName);
        this.navCtrl.navigateForward('/tabs/main-commodities/'+ this.mainCommodityId + '/other/' + item.sId + '/' + item.sName);
      }
   // } else {
     
 //   }
  }
}
