import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_auth/auth.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'tabs/home',
		pathMatch: 'full'
	},
	{
		path: 'login',
		loadChildren: () => import('./account/login/login.module').then(m => m.LoginPageModule)
	},
	{
		path: 'register/:mobile/:type', //  :type = 1: exsting but no pin code; type = 0: new user
		loadChildren: () => import('./account/register/register.module').then(m => m.RegisterPageModule)
	},
	{
		path: 'privacy-policy',
		loadChildren: () => import('./common/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
	},
	{
		path: 'contact-us',
		loadChildren: () => import('./common/contact-us/contact-us.module').then(m => m.ContactUsPageModule)
	},
	{
		path: 'otp/:mobile',
		loadChildren: () => import('./account/otp/otp.module').then(m => m.OtpPageModule)
	},
	{
		path: 'terms-conditions',
		loadChildren: () => import('./common/terms-conditions/terms-conditions.module').then(m => m.TermsConditionsPageModule)
	},
	{
		path: 'commodity-selection',
		loadChildren: () => import('./account/commodity-selection/commodity-selection.module').then(m => m.CommoditySelectionPageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'inactive',
		loadChildren: () => import('./account/inactive/inactive.module').then(m => m.InactivePageModule)
	},
	{
		path: 'tabs',
		loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
		canActivate: [AuthGuard]
	},
	{
		path: 'news/:id/:title',
		loadChildren: () => import('./tabs/home/news/news-detail/news-detail.module').then(m => m.NewsDetailPageModule)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
