import { Component, OnInit } from '@angular/core';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { MenuController, NavController } from '@ionic/angular';
import { DataService } from 'src/app/_services/data.service';

@Component({
  selector: 'app-main-commodities',
  templateUrl: './main-commodities.page.html',
  styleUrls: ['./main-commodities.page.scss'],
})
export class MainCommoditiesPage implements OnInit {
  mainCommodities: any;
  constructor(
    public menu: MenuController,
    private navCtrl: NavController,
    private dataService: DataService,
    private ga: GoogleAnalytics,
  ) { }

  ngOnInit() {
    if(!localStorage.getItem('mainCommodities')) {
      this.getMainCommodities();
    } else {
      this.mainCommodities = JSON.parse(localStorage.getItem('mainCommodities'));
    }
  }
  getMainCommodities() {
    this.dataService.getMainCommodities().then((resp) => {
      localStorage.setItem('mainCommodities', JSON.stringify(resp));
      this.mainCommodities = resp;
    });
  }
  onMainCommdoityClick(item: any) {
    this.ga.trackEvent('User', 'Commodity', 'Main Commodity' + item.mid);
    this.navCtrl.navigateForward('/tabs/main-commodities/'+ item.mId);

  }
}
