import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MenuController, NavController, ToastController } from '@ionic/angular';
import { DataService } from '../../_services/data.service'; 
import { CryptoService } from 'src/app/_services/crypto.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.page.html',
  styleUrls: ['./contact-us.page.scss'],
})
export class ContactUsPage implements OnInit {
  contactForm: FormGroup;
  constructor(
    public menu: MenuController,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private navCtrl: NavController,
    private toastController: ToastController,
    private cryptoService: CryptoService
  ) {
    this.contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: [''],
      mobileNo: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      feedback: ['', Validators.required],
      userId: ['']
    });
    // this.menu.enable(false);
  }

  ngOnInit() {
    //  this.cryptoService.decryptUsingAES256(localStorage.getItem('userID')).subscribe(userId => {
   
    //  });
    const userId = localStorage.getItem('userID');
     if(userId) {
      this.getProfile(userId);
    }
  }
  getProfile(userId: any) {
    this.dataService.getUserInfo(userId).then((resp: any) => {
      this.contactForm.controls.mobileNo.setValue(resp.mobileNumber);
      this.contactForm.controls.firstName.setValue(resp.fName);
      this.contactForm.controls.lastName.setValue(resp.lName);
      this.contactForm.controls.email.setValue(resp.email);
      this.contactForm.controls.userId.setValue(userId);
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.contactForm.controls;
  }
  onFormSubmit(data: any) {

    this.dataService.submitFeedback(data).then((resp: any) =>{
      this.presentToast('Success!', 'Thank you for your message/feedback.', 'top', 3000, 'primary');
      this.navCtrl.back();
    });

  }


  async presentToast(header: string, message: string, position: any, duration: number, color: string) {
    const toast = await this.toastController.create({
      header,
      message,
      position,
      duration,
      color
    });
    toast.present();
  }

}
