import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';

import { DataService } from 'src/app/_services/data.service';

import { Chart } from 'chart.js';
import * as moment from 'moment';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';



@Component({
  selector: 'app-chart-modal',
  templateUrl: './chart-modal.page.html',
  styleUrls: ['./chart-modal.page.scss'],
})
export class ChartModalPage implements OnInit, OnDestroy, AfterViewInit {
  // Data passed in by componentProps
  @Input() chartId: any;
  @Input() graphType: number;
  @Input() storeId: any;
  @Input() productInfo: string;
  @Input() isStoreDetail: boolean;

  @ViewChild('lineCanvas') lineCanvas: ElementRef;

  lineChart: any;
  storeCityName: any;
  isLoading = true;
  isFollowed = false;
  tableData: any;
  storeName: any;
  cityName: any;
  isCordova: boolean;
  constructor(
    public modalController: ModalController,
    private dataService: DataService,
    private socialSharing: SocialSharing,
    public platform: Platform,
    private alertController: AlertController,
    private ga: GoogleAnalytics,
  ) { 
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.isCordova = true;
      } else {
        this.isCordova = false;
      }
    });
  }


  ngOnInit() {
    const modalState = {
      modal: true,
      desc: 'fake state for our modal'
    };
    history.pushState(modalState, null);
    // console.log(this.graphType);
    if (this.graphType === 1) {
      this.getChartRates(this.chartId);
    }
    if (this.graphType === 2) {
      this.getBoardRate(this.chartId);
    }
    if (this.graphType === 3) {
      this.getPoultryRate(this.chartId);
    }
  }
  ngAfterViewInit() {
    //  this.lineChartMethod();

  }

  onCallClick() {
    this.dataService.getCallNumber(this.storeId).then((resp: any) => {
      let contactMsg: string;
      let header: string;
      if (resp === 0) {
        header = 'Info';
        contactMsg = 'Subscribe to get information. Please call our customer care at <a href="tel:+91-9845544475">+91-984-554-4475</a> for more detail.';
      } else
        if (resp === 1) {
          header = 'Alert!'
          contactMsg = 'You have crossed your daily limit. Please call our customer care at  <a href="tel:+91-9845544475">+91-984-554-4475</a> for more details';
        } else {
          header = this.storeCityName;
          contactMsg = '<h3 class="ion-text-center"><a href="tel:' + resp + '">' + resp + '</a></h3><div class="ion-text-center">Tap the number to call</div>';
        }
      this.presentAlert(header, contactMsg);
    });
  }
  async presentAlert(header: string, msg: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header,
      message: msg,
      backdropDismiss: false,
      buttons: ['Okay']
    });

    await alert.present();
  }
  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  getChartRates(id: any) {
    this.dataService.getGraphData(id).then((resp) => {
      //  console.log(resp);
      this.ga.trackEvent('User', 'Store', 'Store Detail' + resp.sName);
      this.storeName = resp.sName;
      this.cityName = resp.city;
      this.storeCityName = resp.sName + ', ' + resp.sCity;
      this.bindChart(resp.GData);
      this.tableData = resp.GData;
      this.tableData.sort((a, b) => {
        return <any>new Date(b.Date) - <any>new Date(a.Date);
      });
      if (+resp.status === 1) {
        this.isFollowed = true;
      } else {
        this.isFollowed = false;
      }
    });
  }

  getBoardRate(id: any) {
    this.dataService.getBoardGraphData(id).then((resp) => {
      //  console.log(resp);
      this.bindChart(resp);
      this.tableData = resp;
      this.tableData.sort((a, b) => {
        return <any>new Date(a.Date) - <any>new Date(b.Date);
      });
    });
  }

  getPoultryRate(id: any) {
    this.dataService.getPoultryGraphData(id).then((resp) => {
      this.ga.trackEvent('User', 'Store', 'Store Detail' + resp.sName);
      this.storeName = resp.sName;
      this.cityName = resp.city;
      this.storeCityName = resp.sName + ', ' + resp.city;
      this.bindChart(resp.GData);
      this.tableData = resp.GData;
      this.tableData.sort((a, b) => {
        return <any>new Date(b.Date) - <any>new Date(a.Date);
      });

      if (+resp.status === 1) {
        this.isFollowed = true;
      } else {
        this.isFollowed = false;
      }
    });
  }
  bindChart(data: any) {
    this.isLoading = false;
    const dateData = [];
    const rateData = [];
    if (!data.GData) {
      for (let i = 0; i < data.length; i++) {
        dateData.push(moment(Date.parse(data[i].Date)).format('MMM-DD'));
        rateData.push(data[i].MaxRate);
      }
    }
    this.lineChartMethod(dateData, rateData);
  }
  whatsappShare() {
    const canvas = this.lineCanvas.nativeElement;
    const img = canvas.toDataURL();
    let shareContent: string;
    if( this.storeName && this.cityName) {
      shareContent = this.storeName + ', ' + this.cityName + '\n ' + this.productInfo + ' historical rates. \n' +
      'Shared from OnlineCMR app. \n\nVisit https://www.onlinecmr.in to get more information.';
    } else {
      shareContent =  this.productInfo + ' historical rates. \n' +
      'Shared from OnlineCMR app. \n\nVisit https://www.onlinecmr.in to get more information.';
    }
    if (this.isCordova) {
      this.socialSharing.shareViaWhatsApp(shareContent, img, null);
    } else {
      window.open('https://api.whatsapp.com/send?text=' + shareContent + '', '_system');
    }
  }

  lineChartMethod(label: any, data: any) {
    this.lineChart = new Chart(this.lineCanvas.nativeElement, {
      type: 'line',
      data: {
        labels: label, // ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'November', 'December'],
        datasets: [
          {
            label: 'Max Rate',
            fill: true,
            lineTension: 0.1,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data, // [65, 59, 80, 81, 56, 55, 40, 10, 5, 50, 10, 15],
            spanGaps: false,
          }
        ]
      }, options: {
        legend: {
          display: false,

        },
        scales: {
          yAxes: [{
            // ticks: {
            //     // Include a dollar sign in the ticks
            //     callback: function(value, index, values) {
            //         return '₹ ' + value;
            //     }
            // },
            scaleLabel: {
              display: true,
              labelString: 'Price in ₹'
            }
          },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Date'
              }
            }
          ]
        }
      }
    });
  }
  onFollowUnfollow(status: any) {
    this.dataService.followUnFollow(this.storeId, status).then((resp: any) => {
      if (parseInt(resp) === 1) {
        this.isFollowed = true;
      } else {
        this.isFollowed = false;
      }
    })
  }

  @HostListener('window:popstate', ['$event'])
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
