import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
.catch(err => console.log(err));

// let BrowserDetect: any = {
//   init: function () {
//     this.browser = this.searchString(this.dataBrowser) || "Other";
//     this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
//   },
//   searchString: function (data) {
//     for (var i = 0; i < data.length; i++) {
//       var dataString = data[i].string;
//       this.versionSearchString = data[i].subString;

//       if (dataString.indexOf(data[i].subString) !== -1) {
//         return data[i].identity;
//       }
//     }
//   },
//   searchVersion: function (dataString) {
//     var index = dataString.indexOf(this.versionSearchString);
//     if (index === -1) {
//       return;
//     }

//     var rv = dataString.indexOf("rv:");
//     if (this.versionSearchString === "Trident" && rv !== -1) {
//       return parseFloat(dataString.substring(rv + 3));
//     } else {
//       return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
//     }
//   },

//   dataBrowser: [
//     { string: navigator.userAgent, subString: "Edge", identity: "MS Edge" },
//     { string: navigator.userAgent, subString: "MSIE", identity: "Explorer" },
//     { string: navigator.userAgent, subString: "Trident", identity: "Explorer" },
//     { string: navigator.userAgent, subString: "Firefox", identity: "Firefox" },
//     { string: navigator.userAgent, subString: "Opera", identity: "Opera" },
//     { string: navigator.userAgent, subString: "OPR", identity: "Opera" },
//     { string: navigator.userAgent, subString: "Chrome", identity: "Chrome" },
//     { string: navigator.userAgent, subString: "Safari", identity: "Safari" }
//   ]
// };

// BrowserDetect.init();
// console.log("You are using <b>" + BrowserDetect.browser + "</b> with version <b>" + BrowserDetect.version + "</b>");

// if (
//   BrowserDetect.browser === 'Chrome' && BrowserDetect.version > 68 ||
//   BrowserDetect.browser === 'Firefox' ||
//   BrowserDetect.browser === 'Safari'
// ) {
//   platformBrowserDynamic().bootstrapModule(AppModule)
//     .catch(err => console.log(err));
// }
// else {
//   window.location.href = 'other-browser.html'
// }
