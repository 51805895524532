import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { AlertController, NavController, ToastController } from '@ionic/angular';
import { DataService } from '../_services/data.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    public alertController: AlertController,
    public navController: NavController,
    public dataService: DataService, 
    public toastController: ToastController
  ) { }


  canActivate(next: ActivatedRouteSnapshot): boolean {
   // console.log('islogged in', this.authService.loggedIn());
    if (this.authService.loggedIn() && this.authService.isUserIdExists()) {
      return true;
    }
    localStorage.clear();
    this.navController.navigateRoot('/login');
    return false;
  }

  async presentToast(header: string, message: string, position: any, duration: number, color: string) {
    const toast = await this.toastController.create({
      header,
      message,
      position,
      duration,
      color
    });
    toast.present();
  }
}
