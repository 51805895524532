import { Injectable } from '@angular/core';
import {
    HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastController, LoadingController, AlertController, NavController } from '@ionic/angular';
import { AuthService } from '../_auth/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    loaderToShow: any;
    private loader: HTMLIonLoadingElement;
    sessionAlert: any;
    constructor(
        private router: Router,
        public toastController: ToastController,
        public loadingController: LoadingController,
        public alertController: AlertController,
        private navController: NavController,
        private authService: AuthService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (request.headers.get('No-Auth') === 'true') {
            if (!request.headers.has('Content-Type')) {
                request = request.clone({
                    setHeaders: {
                        'content-type': 'application/json'
                    }
                });
            }
            request = request.clone({
                headers: request.headers.set('Accept', 'application/json')
            });
            //   this.showLoader();

            return next.handle(request.clone())
                .pipe(
                    map((event: HttpEvent<any>) => {
                        // console.log(event);
                        if (event instanceof HttpResponse) {
                        }
                        this.hideLoader();
                        return event;
                    }),
                    catchError((error: HttpErrorResponse) => {
                    //  alert(error.status);
                        if (error.status === 0) {
                            //   this.showAlert();
                        }
                        if (error.status === 401) {
                            if (error.error.success === false) {
                                //  this.presentToast('server request failed');
                            } else {
                                this.router.navigate(['login']);
                            }
                        }
                        this.hideLoader();
                        return throwError(error);
                    }));
        } else {
            if (this.authService.loggedIn()) {
                const token = localStorage.getItem('token');
                if (token) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: 'Bearer ' + token
                        }
                    });
                }
                if (!request.headers.has('Content-Type')) {
                    request = request.clone({
                        setHeaders: {
                            'content-type': 'application/json'
                        }
                    });
                }
                request = request.clone({
                    headers: request.headers.set('Accept', 'application/json')
                });
                //    this.showLoader();
                return next.handle(request).pipe(
                    map((event: HttpEvent<any>) => {
                        // console.log(event);
                        if (event instanceof HttpResponse) {
                        }
                        // console.log('event finished');
                        this.hideLoader();
                        return event;
                    }),
                    catchError((error: HttpErrorResponse) => {
                      // alert(error.status);
                        if (error.status === 0) {
                            //  this.showAlert();
                        }
                        if (error.status === 401) {
                            // this.sessionExpired();
                        }
                        this.hideLoader();
                        return throwError(error);
                    }));
            } else {
                //  this.sessionExpired();
            }

        }
    }
    async showAlert() {
        const alert = await this.alertController.create({
            header: 'Alert',
            message: 'Please check your internet connection and try again!',
            buttons: [{
                text: 'Okay',
                cssClass: 'primary',
                handler: () => {
                    // console.log('Confirm Okay');
                }
            }]
        });

        await alert.present();
    }

    async sessionExpired() {
        if (!this.sessionAlert) {
            localStorage.clear();
            this.sessionAlert = await this.alertController.create({
                header: 'Alert',
                backdropDismiss: false,
                message: 'Your login session expired. Please login again!',
                buttons: [{
                    text: 'Okay',
                    cssClass: 'primary',
                    handler: () => {
                        this.navController.navigateRoot('/login');
                    }
                }]
            });
            await this.sessionAlert.present();
        }
    }

    async showLoader() {
        if (!this.loader) {
            this.loader = await this.loadingController.create({
                message: 'Processing Server Request...'
            });
        }
        await this.loader.present();
    }
    async hideLoader() {
        // console.log('this.loader', this.loader);
        if (this.loader) {
            await this.loader.dismiss();
            this.loader = null;
        }
    }

    async presentToast(msg: string) {
        const toast = await this.toastController.create({
            message: msg,
            duration: 3000,
            position: 'top'
        });
        toast.present();
    }
}
