import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { NavController, ModalController, AlertController, IonInfiniteScroll, IonContent, IonSelect, Platform } from '@ionic/angular';
import { DataService } from 'src/app/_services/data.service';

@Component({
  selector: 'app-market',
  templateUrl: './market.page.html',
  styleUrls: ['./market.page.scss'],
})

export class MarketPage implements OnInit {
  sliderConfig = {
    slidesPerView: 'auto'
  }
  selectedTab = '';
  isLatest = true;
  mainCommodities: any;
  loader = [1, 2, 3, 4, 5];
  isLoading = true;
  count = 0;

  marketList: any = [];
  pinCode: any;
  commodityName: string;
  @ViewChild(IonInfiniteScroll, { static: true }) infiniteScroll: IonInfiniteScroll;
  @ViewChild(IonContent, { static: false }) content: IonContent;

  @ViewChild('commodity') selectRef: IonSelect;
  showList = true;
  commodityId: any;
  mainCommodityId: any;


  constructor(
    private dataServicre: DataService,
    private navCtrl: NavController,
    public alertController: AlertController,
    public modalController: ModalController,
    public platform: Platform,
    public cdr: ChangeDetectorRef,
    private ga: GoogleAnalytics
  ) {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.platform.resume.subscribe((obs: any) => {
          alert('app focused');
          this.onResume();
        });
        this.platform.pause.subscribe((obs: any) => {
          this.count = 0;
        });
      }
    });
  }

  onResume() {
    if (this.selectedTab == '') {
      this.isLatest = true;
      this.getLatestBuySellOnPinCode(this.pinCode, this.count);
    }
    else {
      this.isLatest = false;
      this.getListingsOnMainCommId(this.selectedTab, this.count);
    }
  }
  ngOnInit() {
    this.ga.trackEvent('User', 'Market Place', 'Market Home ' + localStorage.getItem('mobileNo'));
    const userAddress = JSON.parse(localStorage.getItem('userAddress'));
    if (userAddress) {
      this.pinCode = userAddress.pinCode
    } else {
      this.pinCode = 0;
    }
    this.getMainCommodities();

//    this.getLatestBuySellOnPinCode(this.pinCode, this.count);
    // this.platform.resume.subscribe(async () => {
    //   if (this.selectedTab === '') {
    //     this.getLatestBuySellOnPinCode(this.pinCode, this.count);
    //   } else {
    //     this.getListingsOnMainCommId(this.selectedTab, this.count);
    //   }
    // });
  }

  ionViewDidEnter() {
    this.getLatestBuySellOnPinCode(this.pinCode, this.count);

    this.enableDisableInfiniteScroll(true);
  }

  enableDisableInfiniteScroll(val) {
    this.infiniteScroll.disabled = !val;
  }

  getMainCommodities() {
    this.mainCommodities = null;
    this.cdr.detectChanges();
    const mainComm = localStorage.getItem('mainCommodities');
    if (mainComm) {
      this.mainCommodities = JSON.parse(mainComm);
    } else {
      this.sessionExpired();
    }
  }
  async sessionExpired() {
    localStorage.clear();
    this.navCtrl.navigateRoot('/login');
    // const alert = await this.alertController.create({
    //   header: 'Alert',
    //   backdropDismiss: false,
    //   message: 'Your login session expired. Please login again!',
    //   buttons: [{
    //     text: 'Okay',
    //     cssClass: 'primary',
    //     handler: () => {
    //       this.navCtrl.navigateRoot('/login');
    //     }
    //   }]
    // });
    // await alert.present();
  }

  loadData(event: any) {
    if (this.isLatest) {
      this.getLatestBuySellOnPinCode(this.pinCode, this.count);
    } else {
      this.getListingsOnMainCommId(this.selectedTab, this.count);
    }
    event.target.complete();
  }
  changeTab(item: any) {
    this.count = 0;
    this.marketList.splice(0, this.marketList.length);
    this.isLoading = true;
    if (item == '') {
      this.selectedTab = item;
      this.isLatest = true;
      this.getLatestBuySellOnPinCode(this.pinCode, this.count);
    }
    else {
      this.selectedTab = item.mId;
      this.isLatest = false;
      this.mainCommodityId = item.mId;
      this.commodityName = item.mName;
      // if (item.mId === 26) {
      //   this.isPoultry = true;
      // } else {
      //   this.isPoultry = false;
      // }
      this.getListingsOnMainCommId(this.selectedTab, this.count);
    }
  }
  getLatestBuySellOnPinCode(pin: any, count: any) {
    this.enableDisableInfiniteScroll(true);
    this.dataServicre.GetMarketDataOnPinCode(pin, count).then((resp: any) => {
      if (resp) {
        this.marketList = this.marketList.concat(resp);
        this.count = this.count + 10;
        this.isLoading = false;
      }

      if (resp.length === 0) {
        this.enableDisableInfiniteScroll(false);
      }

    });
  }
  getListingsOnMainCommId(id: any, count: any) {
    this.enableDisableInfiniteScroll(true);
    this.dataServicre.GetMarketDataonMainCommId(id, count).then((resp: any) => {
      if (resp) {
        this.marketList = this.marketList.concat(resp);
        this.count = this.count + 10;
        this.isLoading = false;
      }
      if (resp.length === 0) {
        this.enableDisableInfiniteScroll(false);
      }
    });
  }
  onImgError(event: any) {
    event.target.src = './assets/image_not_available.png'
    // Do other stuff with the event.target
  }
  onListClick(item: any) {
    this.navCtrl.navigateForward('/tabs/market/detail/' + item.id);
  }
  onForm() {
    this.navCtrl.navigateForward('/tabs/market/add-edit/' + 0 + '/' + this.selectedTab + '/' + 0);
    // this.cdr.detectChanges();
    //  this.selectRef.open();
  }
  commoditySelect(commodityId) {
    // console.log('comm', commodityId)
    this.navCtrl.navigateForward('/tabs/market/add-edit/' + 0 + '/' + this.selectedTab + '/' + 0);
  }
}
