import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  oneSignalAndroidId = '3bb774cc-0a80-4429-88b6-0986ab630b84';
  onesignaliOSId = '';
  fireBaseSenderId = '480609434008';



  apiURI = environment.apiURI;

  userStatus = 'Accounts/getUserStatus'; // int userId,string lat,string lon

  getOTP = 'User/getOTP';
  verifyOTP = 'User/verifyOTP';
  getToken = 'RequestToken';
  SetDeviceId = 'Accounts/setUserToken'; // int userId, string palyerId
  getCountries = 'User/getCountries';
  getStates = 'User/getStates';
  getDistricts = 'User/getDistrict';
  getCities = 'User/getCity';
  registerUser = 'User/registerUser';

  getProfileDetails = 'User/getProfileDetails';  // int userId
  UpdatePinCode = 'User/UpdatePinCode';  // int userId, string pincode
  isPincodeExists = 'User/isPincodeExists'; // int userId

  subscribeCommodities = 'User/AddUpdateSubscriptionNew' // body: { commodityIds: [1,2,4], userId: 3345}
 

  getUserSubscriptions = 'User/getUserSubscriptions';

  getMainCommodities = 'Commodity/GetMainCommodities';

  getSubCommodities = 'Commodity/getSubCommoditiesOnMId';

  isServiceAllowed = 'Accounts/isServiceAllowed'; // int userId, int serviceId

  getUsername = 'Accounts/getuserName'; // int userId

  changePassword = 'Accounts/changePassword'; // int userId, string oldPassword, string newPassword

  getSubscribedServicesOnUserId = 'Accounts/getSubscribedServicesOnUserId'; // int userId

  getdashboardMessages = 'Dashboard/getMessages';
  getdashboardMarket = 'Dashboard/DashboardMarket';
  getdashboardRates = 'Dashboard/LatestRates';


  getSubcommodityData = 'Commodity/GetCitiesOnSubcommodityId';
  getExchangeDataOnSubCommodityId = 'Exchange/getExchangeDataOnsId';

  getBoardDataOnSubCommodityId = 'Board/getBoardRatesOnSubId';
  getPoultryData = 'Poultry/getPoultryCityRates';

  GetGraphDataProIdUserId = 'Commodity/GetGraphDataProIdUserId'; // ?PId=int, userId=int
  GetPoultryGraphDataProIdUserId = 'Poultry/getPoultryMapDataOnIdUser';// ?PId=int, userId=int
  GetBoardGraphDataOnId = 'Board/getMapdataOnId'; // id int
  FollowUnfollow = 'Store/updateFollowStatus'; // status

  GetstoreContactInformation = 'Accounts/storeContactInformation'; // int userId, int StoreId


  getFavouriteStores = 'Accounts/getFavouriteStores'; // int userId

  getStoreType = 'Store/getStoreType'; // StoreId

  GetPoultryStoreDataOnStoreId = 'Store/getPoultryStoreDataOnStoreId'; // StoreId

  getPoultryStoreDataOnStoreIdUserId = 'Store/getPoultryStoreDataOnStoreIdUserId'; // StoreId, UserId
  
  GetDataOnStoreid = 'Store/GetDataOnStoreid'; // StoreId
  GetDataOnStoreidUserId = 'Store/GetDataOnStoreidUserId'; // StoreId, UserId
  GetStoreDetailsOnStoreIdUserId = 'Store/GetStoreDetailsOnStoreIdUserId';// int StoreId, int userId
  SendFeedback = 'Accounts/feedback'; // feedbackModal feedData

  // Messages
  // GetSubscribedDList='Messages/GetSubscribedDList'; //int userId, int MId
  // First Call
  GetSubscribedMessagesOnMid = 'Messages/GetSubscribedMessagesOnMid'; // int userId, int MId, int count

  requestForTrial = 'Messages/requestForTrial'; // int userId, int MId,
  TrailStatus = 'Messages/TrailStatus';// int userId, int MId
  /// For all users
  getAllMessagesOnUserIDMId = 'Messages/getAllMessagesOnUserIDMId';// int userId, int MId, int count
  // Latest Call
  getAllMessagesOnUserID = 'Messages/getAllMessagesOnUserID'; // int userId
  // get all messages
  getAllMessagesOnUserIDCount = 'Messages/getAllMessagesOnUserIDCount'; // int userId, int count
  // Market Place
  GetMarketDataOnPinCode = 'Market/GetMarketDataOnPinCode'; // int StateId, int userId, int count

  GetMarketDataOnStateId = 'Market/GetMarketDataOnStateId'; // string PinCode, int userId, int count

  saveNewBuySellOffer = 'Market/saveNewBuySellOffer';

  // int MainCommodityId, int label, int userDetailId, string description, string subject, string expDate

  updateBuysellOnId = 'Market/updateBuysellOnId';
  // int id, int MainCommodityId, int label, int userDetailId, string description, string subject, string expDate

  actDctBuySellOfferOnId = 'Market/actDctBuySellOfferOnId'; // int id, string status

  getAllActiveBuySellOffers = 'Market/getAllActiveBuySellOffers';// int label eg: buy sell

  getLatestBuySellOffers = 'Market/getLatestBuySellOffers'; // int label

  GetMarketDataonMainCommId = 'Market/getBuySellOnCommodityId';// int mId, int userId, int count

  getBuySellDetailOnId = 'Market/getBuySellDetailOnId'; // int id, int userId

  saveReply = 'Market/saveReply';// int id, int userId, string reply

  geMtyBuyCount = 'Market/getMyBuyCount'; // int userId

  getMySellCount = 'Market/getMySellCount'; // int userId

  getMyBuySellBids = 'Market/getMyBuySellBids'; // int userId

  getMyLatestActiveBids = 'Market/getMyLatestActiveBids'; // int userId

  getRelatedBuySellOnId = 'Market/getRelatedBuySellOnId'; // int id, int mId, int userId, int count

  getGradesOnMId = 'Market/GetGradesOnMId'; //  int MId
  getGradesOnSId = 'Market/GetGradesOnSId'; //  int SId

  SubscriptionExists = 'User/isSubscriptionExists'; // int userId

  Jahirath = 'Jahirath/getJahirath'; // int type, int mId
  GetUserType = 'Accounts/getusertype'; // int userId
  // user subcommodity subscribed status
  isSubcommoditySubscribed = 'Accounts/IsRegistered';
  // messages 
  getAllMessagesOnUserIDSId = "Messages/getAllMessagesOnUserIDSId"; // int userId, int SId, int count


  getNewsList = 'DailyNews/GetDailyPaper'; // ?Count=
  getNewsDetail = 'DailyNews/GetNewsDetail'; // ?NewsId=

  // my store

  storeStatus = 'Accounts/GetStoreStatus'; // int userid 
  getUserDataOnId = 'Accounts/getUserDataOnId'; // int userid
  storeId = 'Accounts/GetStoreIdOnUserId'; // int userid
  getMyStoreData = 'Accounts/getMyStoreData'; // int userId  
  getPoultryStoreData = 'Accounts/getPoultryMyStoreData'; // int userId
  addStoreData = 'Accounts/AddPrice' //   storeId: int   mapId: int, min: float, max: float, status: boolean, userId: int
  addPoultryData = 'Accounts/AddPoultryPrice';
  
  submitNewStoreRequest = 'Accounts/addNewMyStoreRequestMobile'; 
  getServerDate = 'Accounts/getServerDate';
  // enquires
  getAllEnquiresonUserId = 'Accounts/getAllEnquiresonUserId';
  getAllSentMessages = "Accounts/getAllSentEnquiresUserId"
  replyTo = 'Accounts/replyTo';
  // get subscribed subcommodties
  getSubscribedSubcommodities = 'Accounts/GetSubcommoditiesOnUserId';
  
  constructor() { }
}
