import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@ionic/angular';
import { DataService } from 'src/app/_services/data.service';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.page.html',
  styleUrls: ['./news-detail.page.scss'],
})
export class NewsDetailPage implements OnInit {

  newsId: any;
  pdfURL: any;
  isCordova: boolean;
  newsTitle: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private socialSharing: SocialSharing,
    private ga: GoogleAnalytics,
    public platform: Platform,
    ) {
      this.platform.ready().then(() => {
        if (this.platform.is('cordova')) {
          this.isCordova = true;
        } else {
          this.isCordova = false;
        }
      });
  }

  ngOnInit() {
    this.newsId = this.activatedRoute.snapshot.params.id;
    this.newsTitle = this.activatedRoute.snapshot.params.title;
    this.ga.trackEvent('User', 'News', 'News Detail: ' + this.newsTitle);
    console.log(this.newsTitle);
    this.getSingleNews(this.newsId);
  }
  getSingleNews(id: any) {
    this.dataService.getNewsDetail(id).then((resp: any) => {
      this.pdfURL = resp;
    })
  }
  shareWhatsapp() {
    let content = '';
    content += 'Read ' + this.newsTitle + ' news on OnlineCMR app at: https://app.onlinecmr.in/news/' + this.newsId + '/' + this.newsTitle +' .\n\n'
    content += 'Shared from OnlineCMR app. \nVisit https://www.onlinecmr.in to get more information.';
    
    if (this.isCordova) {
      this.socialSharing.shareViaWhatsApp(content, null, null);
      } else {
        window.open('https://api.whatsapp.com/send?text=' + content + '', '_system');
      }
  }
}
