import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { CryptoService } from './crypto.service';


@Injectable({
  providedIn: 'root'
})
export class DataService {
userId: any = localStorage.getItem('userID');

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private cryptoService: CryptoService
  ) {
    //  localStorage.getItem('userID')  = localStorage.getItem('userID');
    // console.log('userid from data service', localStorage.getItem('userID'));
  // localStorage.getItem('userID') = this.cryptoService.decryptUsingAES256(localStorage.getItem('userID'));
  // console.log(localStorage.getItem('userID'))
  }

  /**
   * Reports whether the body includes one or more values for a given parameter.
   * @param mno The parameter name.
   * @returns True if the parameter has one or more values,
   * false if it has no value or is not present.
   */
  getOTP(mno: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'true');
    const params = new HttpParams().set('mobileNumber', mno);
    return this.http.get<any>(this.configService.apiURI + this.configService.getOTP, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  verifyOTP(mno: any, otp: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'true');
    const params = new HttpParams().set('contact', mno).set('otp', otp);
    return this.http.get<any>(this.configService.apiURI + this.configService.verifyOTP, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getToken(mno: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'true');
    const params = new HttpParams().set('Username', mno);
    return this.http.get<any>(this.configService.apiURI + 'RequestToken', { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  sendDeviceId(playerId: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('palyerId', playerId);
    return this.http.get<any>(this.configService.apiURI + this.configService.SetDeviceId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getCountries() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'true');
    // const params = new HttpParams().set('mobileNumber', mno).set('otp', otp);
    return this.http.get<any>(this.configService.apiURI + this.configService.getCountries, { headers }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getStates(cId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'true');
    const params = new HttpParams().set('countryid', cId);
    return this.http.get<any>(this.configService.apiURI + this.configService.getStates, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getDistricts(sId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'true');
    const params = new HttpParams().set('stateid', sId);
    return this.http.get<any>(this.configService.apiURI + this.configService.getDistricts, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getCities(dId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'true');
    const params = new HttpParams().set('districtid', dId);
    return this.http.get<any>(this.configService.apiURI + this.configService.getCities, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  registerUser(user: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'true');
    return this.http.post<any>(this.configService.apiURI + this.configService.registerUser, user, { headers }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  
  // subscribeCommodities(id: any, isChecked: boolean) {
  //   const postBody = {
  //     mainCommId: id,
  //     userDetailId: localStorage.getItem('userID'),
  //     status: isChecked
  //   }
  //   let headers: HttpHeaders = new HttpHeaders();
  //   headers = headers.set('No-Auth', 'false');
  //   return this.http.post<any>(this.configService.apiURI + this.configService.subscribeCommodities, postBody, { headers }).toPromise().then(res => {
  //     return res;
  //   }, error => {
  //     console.log(error);
  //     // return error;
  //   }).catch((val) => {
  //     console.log(val);
  //   });
  // }
  

  subscribeCommodities(postBody: any) {
    // const postBody = {
    //   mainCommId: id,
    //   userDetailId: localStorage.getItem('userID'),
    //   status: isChecked
    // }
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    return this.http.post<any>(this.configService.apiURI + this.configService.subscribeCommodities, postBody, { headers }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  
  getSubscriptions() {
    let headers: HttpHeaders = new HttpHeaders();
    const userId = localStorage.getItem('userID')
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID'));
    return this.http.get<any>(this.configService.apiURI + this.configService.getUserSubscriptions, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }


  subscriptionCount(uid: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', uid);
    return this.http.get<any>(this.configService.apiURI + this.configService.SubscriptionExists, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  //  GetUserType
  getUserType() {
    let headers: HttpHeaders = new HttpHeaders();
    const userId = localStorage.getItem('userID')
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', userId);
    return this.http.get<any>(this.configService.apiURI + this.configService.GetUserType, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getMainCommodities() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    return this.http.get<any>(this.configService.apiURI + this.configService.getMainCommodities, { headers }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getSubCommodities(mainCommId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('mId', mainCommId);
    return this.http.get<any>(this.configService.apiURI + this.configService.getSubCommodities, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getSubCommodityData(subid: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('sId', subid);
    return this.http.get<any>(this.configService.apiURI + this.configService.getSubcommodityData, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getExchangeDataOnSubCommodityId(subid: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('sId', subid);
    return this.http.get<any>(this.configService.apiURI + this.configService.getExchangeDataOnSubCommodityId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }


  getBoardRate(subid: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('sId', subid);
    return this.http.get<any>(this.configService.apiURI + this.configService.getBoardDataOnSubCommodityId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  getStoreRate(subid: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('SubCommId', subid);
    return this.http.get<any>(this.configService.apiURI + this.configService.getPoultryData, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  getJahirath(mid: any, sizeid: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('mId', mid).set('type', sizeid);
    return this.http.get<any>(this.configService.apiURI + this.configService.Jahirath, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  getdashboardMessages() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    return this.http.get<any>(this.configService.apiURI + this.configService.getdashboardMessages, { headers }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getdashboardMarket(PinCode: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('PinCode', PinCode)
    return this.http.get<any>(this.configService.apiURI + this.configService.getdashboardMarket, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getdashboardRates() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    return this.http.get<any>(this.configService.apiURI + this.configService.getdashboardRates, { headers }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getSubcommodityData(id: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('SubCommId', id);
    return this.http.get<any>(this.configService.apiURI + this.configService.getSubcommodityData, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  getGraphData(id: any) {
    //   const userId: any = localStorage.getItem('userID')
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('PId', id).set('userId', localStorage.getItem('userID'));
    return this.http.get<any>(this.configService.apiURI + this.configService.GetGraphDataProIdUserId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  getPoultryGraphData(id: any) {
    //  const userId: any = localStorage.getItem('userID')
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('PId', id).set('userId', localStorage.getItem('userID'));
    return this.http.get<any>(this.configService.apiURI + this.configService.GetPoultryGraphDataProIdUserId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getBoardGraphData(id: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('id', id);
    return this.http.get<any>(this.configService.apiURI + this.configService.GetBoardGraphDataOnId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  followUnFollow(id: any, status: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('storeid', id).set('status', status);
    return this.http.get<any>(this.configService.apiURI + this.configService.FollowUnfollow, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getCallNumber(storeId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('StoreId', storeId);
    return this.http.get<any>(this.configService.apiURI + this.configService.GetstoreContactInformation, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  // latest buy-sell
  getLatestMarket(id: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('id', id);
    return this.http.get<any>(this.configService.apiURI + this.configService.getLatestBuySellOffers, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  // get fav stores
  getFavouriteStores() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID'));
    return this.http.get<any>(this.configService.apiURI + this.configService.getFavouriteStores, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getStoreType(storeId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('StoreId', storeId);
    return this.http.get<any>(this.configService.apiURI + this.configService.getStoreType, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getFavPoultryData(storeId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('StoreId', storeId).set('UserId', localStorage.getItem('userID'));
    return this.http.get<any>(this.configService.apiURI + this.configService.getPoultryStoreDataOnStoreIdUserId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getFavOtherData(storeId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('StoreId', storeId).set('UserId', localStorage.getItem('userID'));
    return this.http.get<any>(this.configService.apiURI + this.configService.GetDataOnStoreidUserId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getFavStoreDetail(storeId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('StoreId', storeId).set('userId', localStorage.getItem('userID'));
    return this.http.get<any>(this.configService.apiURI + this.configService.GetStoreDetailsOnStoreIdUserId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  checkUserStatus(userId: any, lat: any, long: any, version: string) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', userId).set('lat', lat).set('lon', long).set('version', version);
    return this.http.get<any>(this.configService.apiURI + this.configService.userStatus, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  // sms messages
  getSubscribedDList(mainCommId: any, count: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('MId', mainCommId).set('count', count);
    return this.http.get<any>(this.configService.apiURI + this.configService.GetSubscribedMessagesOnMid, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  requestForTrial(mainCommId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('MId', mainCommId);
    return this.http.get<any>(this.configService.apiURI + this.configService.requestForTrial, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  trialStatus(id: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('MId', id);
    return this.http.get<any>(this.configService.apiURI + this.configService.TrailStatus, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  getAllMessagesOnUserIDMId(mainCommId: any, count: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('MId', mainCommId).set('count', count);
    return this.http.get<any>(this.configService.apiURI + this.configService.getAllMessagesOnUserIDMId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }



  GetSubscribedMessagesOnMid(mainCommId: any, count: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('MId', mainCommId).set('count', count);
    return this.http.get<any>(this.configService.apiURI + this.configService.GetSubscribedMessagesOnMid, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }


  getAllMessagesOnUserIDCount(count: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('count', count);
    return this.http.get<any>(this.configService.apiURI + this.configService.getAllMessagesOnUserIDCount, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getAllMessagesOnUserId() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID'));
    return this.http.get<any>(this.configService.apiURI + this.configService.getAllMessagesOnUserID, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  // market rate
  GetMarketDataOnPinCode(PinCode: any, count: any) {
    console.log(localStorage.getItem('userID'));
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('PinCode', PinCode).set('count', count);
    return this.http.get<any>(this.configService.apiURI + this.configService.GetMarketDataOnPinCode, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  GetMarketDataOnStateId(StateId: any, count: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('StateId', StateId).set('count', count);
    return this.http.get<any>(this.configService.apiURI + this.configService.GetMarketDataOnStateId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  GetMarketDataonMainCommId(MId: any, count: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('mId', MId).set('count', count);
    return this.http.get<any>(this.configService.apiURI + this.configService.GetMarketDataonMainCommId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getGrades(id: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('SId', id);
    return this.http.get<any>(this.configService.apiURI + this.configService.getGradesOnSId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  // getBuySellDetailOnId

  getBuySellDetailOnId(id: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('id', id);
    return this.http.get<any>(this.configService.apiURI + this.configService.getBuySellDetailOnId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  getRelatedListings(id: any, mId: any, count: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('id', id).set('mId', mId).set('userId', localStorage.getItem('userID')).set('count', count);
    // int id, int mId, int userId, int count
    return this.http.get<any>(this.configService.apiURI + this.configService.getRelatedBuySellOnId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }


  // save new listing
  addNewBuySell(data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    return this.http.post<any>(this.configService.apiURI + this.configService.saveNewBuySellOffer, data, { headers }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }


  // update buysell listing

  // int id, int MainCommodityId, int label, int userDetailId, string description, string subject, string expDate

  updateBuySell(data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    return this.http.post<any>(this.configService.apiURI + this.configService.updateBuysellOnId, data, { headers }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }


  getMyBuySells() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID'));
    return this.http.get<any>(this.configService.apiURI + this.configService.getMyBuySellBids, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }


  pinCodeCheck(userId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', userId);
    return this.http.get<any>(this.configService.apiURI + this.configService.isPincodeExists, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  getUserInfo(userId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', userId);
    return this.http.get<any>(this.configService.apiURI + this.configService.getProfileDetails, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }



  submitFeedback(data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'true');
    return this.http.post<any>(this.configService.apiURI + this.configService.SendFeedback, data, { headers }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  addEditBuySell(data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    return this.http.post<any>(this.configService.apiURI + this.configService.saveNewBuySellOffer, data, { headers }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  GetUserType(userId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID'));
    return this.http.get<any>(this.configService.apiURI + this.configService.GetUserType, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  // messages
  getAllMessagesOnUserIDSId( SId: any, count: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('SId', SId).set('count', count);
    return this.http.get<any>(this.configService.apiURI + this.configService.getAllMessagesOnUserIDSId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  // store
  getStoreStatus(userId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', userId);
    return this.http.get<any>(this.configService.apiURI + this.configService.storeStatus, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getStoreUserData(userId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', userId);
    return this.http.get<any>(this.configService.apiURI + this.configService.getUserDataOnId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getStoreId(userId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', userId);
    return this.http.get<any>(this.configService.apiURI + this.configService.storeId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getServerDate() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    return this.http.get<any>(this.configService.apiURI + this.configService.getServerDate, { headers }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getMyStoreData(userId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', userId);
    return this.http.get<any>(this.configService.apiURI + this.configService.getMyStoreData, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  getPoultryStoreData(userId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', userId);
    return this.http.get<any>(this.configService.apiURI + this.configService.getPoultryStoreData, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  newStoreRequest(data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    return this.http.post<any>(this.configService.apiURI + this.configService.submitNewStoreRequest, data, { headers }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }

  addRates(data) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
   return from(data).pipe(concatMap(item =>
      this.http.post<any>(this.configService.apiURI + this.configService.addStoreData, item, { headers })
    )).subscribe(response => {
      console.log(response);
    }, // do something with responses
      error => {
        console.error(error);
      }, // so something on error 
      () => {
        console.info("All requests done");
      } // do something when all requests are done 
    );
  }
  // getallEnquires
  getAllEnquires(userId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', userId);
    return this.http.get<any>(this.configService.apiURI + this.configService.getAllEnquiresonUserId, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getAllSentMessages(userId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', userId);
    return this.http.get<any>(this.configService.apiURI + this.configService.getAllSentMessages, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  replyTo(data: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    return this.http.post<any>(this.configService.apiURI + this.configService.replyTo, data, { headers }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getSubscribedSubcommoties() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID'));
    return this.http.get<any>(this.configService.apiURI + this.configService.getSubscribedSubcommodities, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getSubscribedSubcommodiyStatus(subCommId: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('userId', localStorage.getItem('userID')).set('subcommid', subCommId);
    return this.http.get<any>(this.configService.apiURI + this.configService.isSubcommoditySubscribed, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getNewsList(count: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'false');
    const params = new HttpParams().set('Count', count);
    return this.http.get<any>(this.configService.apiURI + this.configService.getNewsList, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
  getNewsDetail(id) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set('No-Auth', 'true');
    const params = new HttpParams().set('NewsId', id);
    return this.http.get<any>(this.configService.apiURI + this.configService.getNewsDetail, { headers, params }).toPromise().then(res => {
      return res;
    }, error => {
      console.log(error);
      // return error;
    }).catch((val) => {
      console.log(val);
    });
  }
}
