import { Component, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Network } from '@ionic-native/network/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { AlertController, IonRouterOutlet, NavController, Platform } from '@ionic/angular';
import { ConfigService } from 'src/app/_services/config.service';
import { NativeGeocoder, NativeGeocoderOptions, NativeGeocoderResult } from '@ionic-native/native-geocoder/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';


import { LoginPage } from './account/login/login.page';
import { PrivacyPolicyPage } from './common/privacy-policy/privacy-policy.page';
import { ContactUsPage } from './common/contact-us/contact-us.page';
import { HomePage } from './tabs/home/home.page';
import { DetailPage } from './tabs/market/detail/detail.page';
import { MainCommoditiesPage } from './tabs/main-commodities/main-commodities.page';
import { SubcommoditiesPage } from './tabs/main-commodities/subcommodities/subcommodities.page';
import { MarketPage } from './tabs/market/market.page';
import { OtherPage } from './tabs/_shared/other/other.page';
import { PoultryPage } from './tabs/_shared/poultry/poultry.page';
import { NewsDetailPage } from './tabs/home/news/news-detail/news-detail.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  //
  /* get a reference to the used IonRouterOutlet
assuming this code is placed in the component
that hosts the main router outlet, probably app.components */
  @ViewChild(IonRouterOutlet, { static: false }) routerOutlet: IonRouterOutlet;
  isCordova: boolean;

  interVal: any;
  // Location coordinates
  latitude: number;
  longitude: number;
  accuracy: number;
  // Geocoder configuration
  geoencoderOptions: NativeGeocoderOptions = {
    useLocale: true,
    maxResults: 5
  };
  // Readable Address
  address: string;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navController: NavController,
    private router: Router,
    public alertController: AlertController,
    private oneSignal: OneSignal,
    private configService: ConfigService,
    public network: Network,
    private geolocation: Geolocation,
    private nativeGeocoder: NativeGeocoder,
    private deeplinks: Deeplinks,
    private ga: GoogleAnalytics
  ) {


    this.initializeApp();

    let connectedToInternet = true;
    network.onDisconnect().subscribe(async () => {
      connectedToInternet = false;
      let alert = await this.alertController.create({
        header: 'Disconnected',
        backdropDismiss: false,
        message: 'Please Connect to the Internet!',
        buttons: [{
          text: 'Okay',
          cssClass: 'primary',
          handler: () => {
            alert = null;
          }
        }]
      });
      if (alert) {
        await alert.present();
      }
    });


    network.onConnect().subscribe(async () => {
      if (!connectedToInternet) {
        const alert = await this.alertController.create({
          header: 'Connected',
          backdropDismiss: false,
          message: 'Network connected.',
          buttons: [{
            text: 'Okay',
            cssClass: 'primary',
            handler: () => {
              // this.navController.navigateRoot('/tabs/home');
              window.location.reload();
            }
          }]
        });

        await alert.present();
      }
      // connectSubscription.unsubscribe();
    });

    // subscribe to router events and send page views to Google Analytics
    // this.router.events.subscribe(event => {

    //   if (event instanceof NavigationEnd) {
    //     console.log('event fired');
    //     this.ga.startTrackerWithId('UA-53645372-1')
    //       .then(() => {
    //         console.log('Google analytics is ready now');
    //         // this.ga.trackView('Page', event.urlAfterRedirects, true);
    //         // if (localStorage.getItem('userID')) {
    //         //   this.ga.setVar('User ID', localStorage.getItem('userID'))
    //         //   this.ga.trackEvent('Page Visit', event.urlAfterRedirects, 'Current Page', parseInt(localStorage.getItem('userID')));
    //         // } else {
    //         //   this.ga.trackEvent('Page Visit', event.urlAfterRedirects, 'Current Page');
    //         // }
    //         // Tracker is ready
    //         // You can now track pages or set additional information such as AppVersion or UserId
    //       })
    //       .catch(e => {
    //         console.log('Error starting GoogleAnalytics', e)
    //       });


    //     // ga('set', 'page', event.urlAfterRedirects);
    //     // ga('send', 'pageview');

    //   }

    // });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.isCordova = true;
      } else {
        this.isCordova = false;
      }
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.exitApp();
      if (this.isCordova) {
        this.ga.startTrackerWithId('UA-53645372-1')
        .then(() => {
          this.ga.setAppVersion('1.0.13');
         // console.log('Google analytics is ready now');
        })
        .catch(e => {
          console.log('Error starting GoogleAnalytics', e)
        });

        this.setupPush();
        this.getGeolocation();
      }
      this.handleDeepLinks();
    });
  }

  handleDeepLinks() {
    this.deeplinks.routeWithNavController(this.navController, {
      '/login': LoginPage,
      '/privacy-policy': PrivacyPolicyPage,
      '/contact-us': ContactUsPage,
      '/tabs/home': HomePage,
      '/tabs/market': MarketPage,
      '/tabs/market/detail/:id': DetailPage,
      '/tabs/main-commodities': MainCommoditiesPage,
      '/tabs/main-commodities/:mainid': SubcommoditiesPage,
      '/tabs/main-commodities/:mainid/other/:subid/:name': OtherPage,
      '/tabs/main-commodities/:mainid/poultry/:subid/:name': PoultryPage,
      '/news/:id/:title': NewsDetailPage
    }).subscribe(match => {
      // match.$route - the route we matched, which is the matched entry from the arguments to route()
      // match.$args - the args passed in the link
      // match.$link - the full link data
      //'Successfully matched route', match);
      this.navController.navigateForward(match.$link.path);
    }, nomatch => {
      // nomatch.$link - the full link data
      console.error('Got a deeplink that didn\'t match', nomatch);
    });
  }

  // Get current coordinates of device
  getGeolocation() {
    this.geolocation.getCurrentPosition().then((resp) => {
      this.latitude = resp.coords.latitude;
      this.longitude = resp.coords.longitude;
      this.accuracy = resp.coords.accuracy;

      this.getGeoencoder(resp.coords.latitude, resp.coords.longitude);

    }).catch(async (error) => {
      console.log('Error getting location' + JSON.stringify(error));
      // const alert = await this.alertController.create({
      //   header: 'Heads up!',
      //   message: 'Please allow access to your location after reopening the app to serve you better.',
      //   backdropDismiss: false,
      //   buttons: [
      //     {
      //       text: 'Okay',
      //       cssClass: 'primary',
      //       handler: () => {
      //         navigator['app'].exitApp();
      //       }
      //     }
      //   ]
      // });
      // await alert.present();
    });
  }

  // geocoder method to fetch address from coordinates passed as arguments
  getGeoencoder(latitude: any, longitude: any) {
    this.nativeGeocoder.reverseGeocode(latitude, longitude, this.geoencoderOptions)
      .then((result: NativeGeocoderResult[]) => {
        this.address = this.generateAddress(result[0]);
      //  console.log('geocode', result)
        this.storeAddresslocalStorage(result);
      })
      .catch((error: any) => {
        console.log('Error getting address' + JSON.stringify(error));
      });
  }

  storeAddresslocalStorage(address: any) {
    const arr = {
      countryName: address[0].countryName,
      countryCode: address[0].countryCode,
      state: address[0].administrativeArea, // state
      district: address[0].subAdministrativeArea, // district
      city: address[0].locality, // city
      area: address[0].subLocality, // area
      postOffice: address[1].subLocality,
      pinCode: address[1].postalCode,
      location: address[1].thoroughfare,
      latitude: this.latitude,
      longitude: this.longitude
    }
    localStorage.setItem('userAddress', JSON.stringify(arr));
  }
  // Return Comma saperated address
  generateAddress(addressObj: any) {
    const obj = [];
    let address = '';
    for (const key in addressObj) {
      obj.push(addressObj[key]);
    }
    obj.reverse();
    for (const val in obj) {
      if (obj[val].length)
        address += obj[val] + ', ';
    }
    return address.slice(0, -2);
  }

  exitApp() {
    this.platform.backButton.subscribeWithPriority(0, async () => {
      // const modal = await this.modalCtrl.getTop();
      // if (modal) {
      //     modal.dismiss();
      // }
      if (
        this.router.url === '/tabs/main-commodities'
        || this.router.url === '/tabs/messages'
        || this.router.url === '/tabs/market'
        || this.router.url === '/tabs/my-business'
        || this.router.url === '/tabs/following'
        || this.router.url === '/tabs/user/subscription'
        || this.router.url === '/tabs/user/profile'
        || this.router.url === '/tabs/user/buy-sell'
      ) {
        this.router.navigateByUrl('/tabs/home');
      }
      if (
        this.router.url === '/login'
        || this.router.url === '/tabs/home'
        || this.router.url === '/inactive'
      ) {
        const alert = await this.alertController.create({
          header: 'Confirm!',
          message: 'Do you want to exit the app?',
          backdropDismiss: false,
          buttons: [
            {
              text: 'No',
              role: 'cancel',
              cssClass: 'secondary',
              handler: (blah) => {
              }
            }, {
              text: 'Yes',
              cssClass: 'primary',
              handler: () => {
                navigator['app'].exitApp();
              }
            }
          ]
        });
        await alert.present();
      } else {
        this.navController.pop();
      }
      // if (this.appPages.some(page => page.url === this.router.url)) {
      //   this.router.navigateByUrl('/tabs/home');
      // }
    });
  }

  // oneSignal

  setupPush() {
   // console.log('push setup')
    if (this.platform.is('android')) {
      this.oneSignal.startInit(this.configService.oneSignalAndroidId, this.configService.fireBaseSenderId);
    }
    if (this.platform.is('ios')) {
      this.oneSignal.startInit('onseSignalAppId');
    }

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);

    // Notifcation was received in general
    this.oneSignal.handleNotificationReceived().subscribe(data => {
      const msg = data.payload.body;
      const title = data.payload.title;
      const additionalData = data.payload.additionalData;
    }, (reason: any) => {
    });

    // Notification was really clicked/opened
    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // Just a note that the data is a different place here!
      const additionalData = data.notification.payload.additionalData;
   //   console.log(additionalData);
      //  this.router.navigateByUrl(additionalData.url)
    }, (reason: any) => {
      // console.log('notification error', reason);
    });
    this.oneSignal.endInit();
    // Then You Can Get Devices ID

    this.oneSignal.getIds().then(identity => {
      console.log('identity', identity.userId);
      localStorage.setItem('playerId', identity.userId);

    }, (reason: any) => {
      //  console.log('notification error', reason);
    });

  }

  navigateToPage(jsonData: any) {
    //  console.log(jsonData);
    const url = jsonData.notification.payload.additionalData;
    //  console.log(url)
    this.navController.navigateForward(url.app_url);
  }

}
